// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-abdominal-bloating-js": () => import("./../src/pages/articles/abdominal-bloating.js" /* webpackChunkName: "component---src-pages-articles-abdominal-bloating-js" */),
  "component---src-pages-articles-bowel-cancer-screening-js": () => import("./../src/pages/articles/bowel-cancer-screening.js" /* webpackChunkName: "component---src-pages-articles-bowel-cancer-screening-js" */),
  "component---src-pages-articles-bowel-polyps-js": () => import("./../src/pages/articles/bowel-polyps.js" /* webpackChunkName: "component---src-pages-articles-bowel-polyps-js" */),
  "component---src-pages-articles-capsule-endoscopy-js": () => import("./../src/pages/articles/capsule-endoscopy.js" /* webpackChunkName: "component---src-pages-articles-capsule-endoscopy-js" */),
  "component---src-pages-articles-coeliac-disease-js": () => import("./../src/pages/articles/coeliac-disease.js" /* webpackChunkName: "component---src-pages-articles-coeliac-disease-js" */),
  "component---src-pages-articles-constipation-js": () => import("./../src/pages/articles/constipation.js" /* webpackChunkName: "component---src-pages-articles-constipation-js" */),
  "component---src-pages-articles-covid-19-planning-old-js": () => import("./../src/pages/articles/covid-19-planning_OLD.js" /* webpackChunkName: "component---src-pages-articles-covid-19-planning-old-js" */),
  "component---src-pages-articles-covid-19-planning-js": () => import("./../src/pages/articles/covid-19-planning.js" /* webpackChunkName: "component---src-pages-articles-covid-19-planning-js" */),
  "component---src-pages-articles-covid-19-telehealth-js": () => import("./../src/pages/articles/covid-19-telehealth.js" /* webpackChunkName: "component---src-pages-articles-covid-19-telehealth-js" */),
  "component---src-pages-articles-diverticulosis-and-diverticulitis-js": () => import("./../src/pages/articles/diverticulosis-and-diverticulitis.js" /* webpackChunkName: "component---src-pages-articles-diverticulosis-and-diverticulitis-js" */),
  "component---src-pages-articles-endoscopic-retrograde-cholangiopancreatography-ercp-js": () => import("./../src/pages/articles/endoscopic-retrograde-cholangiopancreatography-ERCP.js" /* webpackChunkName: "component---src-pages-articles-endoscopic-retrograde-cholangiopancreatography-ercp-js" */),
  "component---src-pages-articles-fatty-liver-js": () => import("./../src/pages/articles/fatty-liver.js" /* webpackChunkName: "component---src-pages-articles-fatty-liver-js" */),
  "component---src-pages-articles-gallstones-js": () => import("./../src/pages/articles/gallstones.js" /* webpackChunkName: "component---src-pages-articles-gallstones-js" */),
  "component---src-pages-articles-gastroenteritis-in-children-js": () => import("./../src/pages/articles/gastroenteritis-in-children.js" /* webpackChunkName: "component---src-pages-articles-gastroenteritis-in-children-js" */),
  "component---src-pages-articles-haemochromatosis-js": () => import("./../src/pages/articles/haemochromatosis.js" /* webpackChunkName: "component---src-pages-articles-haemochromatosis-js" */),
  "component---src-pages-articles-heartburn-and-reflux-js": () => import("./../src/pages/articles/heartburn-and-reflux.js" /* webpackChunkName: "component---src-pages-articles-heartburn-and-reflux-js" */),
  "component---src-pages-articles-helicobacter-pylori-js": () => import("./../src/pages/articles/helicobacter-pylori.js" /* webpackChunkName: "component---src-pages-articles-helicobacter-pylori-js" */),
  "component---src-pages-articles-hepatitis-a-js": () => import("./../src/pages/articles/hepatitis-a.js" /* webpackChunkName: "component---src-pages-articles-hepatitis-a-js" */),
  "component---src-pages-articles-hepatitis-b-js": () => import("./../src/pages/articles/hepatitis-b.js" /* webpackChunkName: "component---src-pages-articles-hepatitis-b-js" */),
  "component---src-pages-articles-hepatitis-c-js": () => import("./../src/pages/articles/hepatitis-c.js" /* webpackChunkName: "component---src-pages-articles-hepatitis-c-js" */),
  "component---src-pages-articles-index-js": () => import("./../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-articles-inflammatory-bowel-disease-ibd-crohns-and-colitis-js": () => import("./../src/pages/articles/inflammatory-bowel-disease-IBD-crohns-and-colitis.js" /* webpackChunkName: "component---src-pages-articles-inflammatory-bowel-disease-ibd-crohns-and-colitis-js" */),
  "component---src-pages-articles-iron-deficiency-js": () => import("./../src/pages/articles/iron-deficiency.js" /* webpackChunkName: "component---src-pages-articles-iron-deficiency-js" */),
  "component---src-pages-articles-irritable-bowel-syndrome-ibs-js": () => import("./../src/pages/articles/irritable-bowel-syndrome-IBS.js" /* webpackChunkName: "component---src-pages-articles-irritable-bowel-syndrome-ibs-js" */),
  "component---src-pages-articles-lactase-deficiency-lactose-intolerance-js": () => import("./../src/pages/articles/lactase-deficiency-lactose-intolerance.js" /* webpackChunkName: "component---src-pages-articles-lactase-deficiency-lactose-intolerance-js" */),
  "component---src-pages-articles-non-coeliac-gluten-sensitivity-real-or-imagined-js": () => import("./../src/pages/articles/non-coeliac-gluten-sensitivity-real-or-imagined.js" /* webpackChunkName: "component---src-pages-articles-non-coeliac-gluten-sensitivity-real-or-imagined-js" */),
  "component---src-pages-articles-ppis-and-dementia-js": () => import("./../src/pages/articles/ppis-and-dementia.js" /* webpackChunkName: "component---src-pages-articles-ppis-and-dementia-js" */),
  "component---src-pages-articles-pruritis-ani-js": () => import("./../src/pages/articles/pruritis-ani.js" /* webpackChunkName: "component---src-pages-articles-pruritis-ani-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletters-index-js": () => import("./../src/pages/Newsletters/index.js" /* webpackChunkName: "component---src-pages-newsletters-index-js" */),
  "component---src-pages-our-team-index-js": () => import("./../src/pages/our-team/index.js" /* webpackChunkName: "component---src-pages-our-team-index-js" */),
  "component---src-pages-our-team-angel-li-js": () => import("./../src/pages/our-team/angel-li.js" /* webpackChunkName: "component---src-pages-our-team-angel-li-js" */),
  "component---src-pages-our-team-claire-gordon-js": () => import("./../src/pages/our-team/claire-gordon.js" /* webpackChunkName: "component---src-pages-our-team-claire-gordon-js" */),
  "component---src-pages-our-team-daniel-cheng-js": () => import("./../src/pages/our-team/daniel-cheng.js" /* webpackChunkName: "component---src-pages-our-team-daniel-cheng-js" */),
  "component---src-pages-our-team-danielle-lam-js": () => import("./../src/pages/our-team/danielle-lam.js" /* webpackChunkName: "component---src-pages-our-team-danielle-lam-js" */),
  "component---src-pages-our-team-duy-pham-js": () => import("./../src/pages/our-team/duy-pham.js" /* webpackChunkName: "component---src-pages-our-team-duy-pham-js" */),
  "component---src-pages-our-team-hannah-hessamodini-js": () => import("./../src/pages/our-team/hannah-hessamodini.js" /* webpackChunkName: "component---src-pages-our-team-hannah-hessamodini-js" */),
  "component---src-pages-our-team-ian-yusoff-js": () => import("./../src/pages/our-team/ian-yusoff.js" /* webpackChunkName: "component---src-pages-our-team-ian-yusoff-js" */),
  "component---src-pages-our-team-katrina-rogers-js": () => import("./../src/pages/our-team/katrina-rogers.js" /* webpackChunkName: "component---src-pages-our-team-katrina-rogers-js" */),
  "component---src-pages-our-team-marcelo-leal-js": () => import("./../src/pages/our-team/marcelo-leal.js" /* webpackChunkName: "component---src-pages-our-team-marcelo-leal-js" */),
  "component---src-pages-our-team-muna-salama-js": () => import("./../src/pages/our-team/muna-salama.js" /* webpackChunkName: "component---src-pages-our-team-muna-salama-js" */),
  "component---src-pages-our-team-nabil-siddique-js": () => import("./../src/pages/our-team/nabil-siddique.js" /* webpackChunkName: "component---src-pages-our-team-nabil-siddique-js" */),
  "component---src-pages-our-team-sam-rao-js": () => import("./../src/pages/our-team/sam-rao.js" /* webpackChunkName: "component---src-pages-our-team-sam-rao-js" */),
  "component---src-pages-our-team-spiro-raftopoulos-js": () => import("./../src/pages/our-team/spiro-raftopoulos.js" /* webpackChunkName: "component---src-pages-our-team-spiro-raftopoulos-js" */),
  "component---src-pages-patient-information-fees-and-charges-js": () => import("./../src/pages/patient-information/fees-and-charges.js" /* webpackChunkName: "component---src-pages-patient-information-fees-and-charges-js" */),
  "component---src-pages-patient-information-first-visit-js": () => import("./../src/pages/patient-information/first-visit.js" /* webpackChunkName: "component---src-pages-patient-information-first-visit-js" */),
  "component---src-pages-patient-information-index-js": () => import("./../src/pages/patient-information/index.js" /* webpackChunkName: "component---src-pages-patient-information-index-js" */),
  "component---src-pages-patient-information-patient-forms-js": () => import("./../src/pages/patient-information/patient-forms.js" /* webpackChunkName: "component---src-pages-patient-information-patient-forms-js" */),
  "component---src-pages-services-bowel-cancer-screening-js": () => import("./../src/pages/services/bowel-cancer-screening.js" /* webpackChunkName: "component---src-pages-services-bowel-cancer-screening-js" */),
  "component---src-pages-services-bowel-preparation-js": () => import("./../src/pages/services/bowel-preparation.js" /* webpackChunkName: "component---src-pages-services-bowel-preparation-js" */),
  "component---src-pages-services-colonoscopy-js": () => import("./../src/pages/services/colonoscopy.js" /* webpackChunkName: "component---src-pages-services-colonoscopy-js" */),
  "component---src-pages-services-endoscopic-retrograde-cholangiopancreatography-ercp-js": () => import("./../src/pages/services/endoscopic-retrograde-cholangiopancreatography-ercp.js" /* webpackChunkName: "component---src-pages-services-endoscopic-retrograde-cholangiopancreatography-ercp-js" */),
  "component---src-pages-services-flexible-sigmoidoscopy-js": () => import("./../src/pages/services/flexible-sigmoidoscopy.js" /* webpackChunkName: "component---src-pages-services-flexible-sigmoidoscopy-js" */),
  "component---src-pages-services-gastroscopy-upper-endoscopy-js": () => import("./../src/pages/services/gastroscopy-upper-endoscopy.js" /* webpackChunkName: "component---src-pages-services-gastroscopy-upper-endoscopy-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-percutaneous-endoscopic-gastrostomy-peg-js": () => import("./../src/pages/services/percutaneous-endoscopic-gastrostomy-peg.js" /* webpackChunkName: "component---src-pages-services-percutaneous-endoscopic-gastrostomy-peg-js" */),
  "component---src-pages-testimonials-js": () => import("./../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

